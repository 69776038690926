.add-negative-targeting-modal .modal-body{
    height: 100%;
    min-width: 800px;
    padding: 0px;
}

.add-negative-targeting-modal {
    overflow: hidden;
}

.add-negative-targeting-modal-footer {
    justify-content: center;
}

.mst-negative-modal-drop-down-height {
    max-height: 300px !important;
}