.textarea-add-targeting {
  border: 1px solid #ADC6CC;
  border-radius: 8px;
  max-height: 485px;/*MS-258 ASIN table scroll issue*/
}

.textarea-scroll{
  max-height : none !important;/*MS-258 ASIN table scroll issue*/
}

.checkboxgap {
  column-gap: 80px;
  margin-top: 10px;
  margin-bottom: 21px;
}

.table-heading-style {
  background-color: #FFFFFF;
  border: 1px solid #E1DFEB;
  box-shadow: 0px 2px 10px rgba(77, 65, 132, 0.12);
  border-radius: 8px;
}

.remove-all-btn {
  background: #FFE9EA;
  border-radius: 24px;
  line-height: 20px;
  padding: 6px 20px;
  color: #B91C1C;
  opacity: 0.5;
  margin:11px 12px;
}

.remove-all-btn-dark {
  background: #B91C1C;
  border-radius: 24px;
  line-height: 20px;
  padding: 6px 20px;
  color: #ffffff;
  margin:11px 12px;
}

.add-all-btn {
  background:  rgba(110, 231, 183, 0.3);
  border-radius: 24px;
  line-height: 20px;
  padding: 6px 20px;
  color: #047857;
  opacity: 0.5;
  margin:11px 12px;
}

.add-all-btn-dark {
  background: #047857;  
  border-radius: 24px;
  line-height: 20px;
  padding: 6px 20px;
  color: #FFFFFF;
  margin:11px 12px;
  box-shadow: 0px 4px 20px rgba(77, 65, 132, 0.2);
}



.modal-wrapper .modal-dialog {
  padding: 20px !important;
  max-width:1200px !important;
}

.m-0{
  margin: 0px !important;
}
.p-0{
  padding: 0px !important;
}
.border-right-1{
  border-right: 1px solid #E1DFEB  ;
}
.p-32px{
  padding: 32px;
}
.pl-12px{
  padding-left: 12px;
}
.pr-12px{
  padding-right: 12px;
}
.pb-12px{
  padding-bottom: 12px;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}

.btn-close{
  position: relative;
    top: 20px;
    right: 15px;
}
.modal-header{
  padding: 0px ;
}
.abc{
  width:100%
}
