
.more-filter-modal.quick-filter-modal .modal-dialog {
    width: 360px !important;
}


.more-filter-modal .modal-dialog {
    position: fixed;
    margin: auto;
    width: 280px;
    height: 100%;
    right: 0;
    -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
         -o-transform: translate3d(0%, 0, 0);
            transform: translate3d(0%, 0, 0);
}


.more-filter-modal .modal-content {
    height: 100%;
    overflow-y: auto;
    width: auto;
}

.more-filter-modal .modal.left .modal-body,
.more-filter-modal .modal.right .modal-body {
    padding: 15px 15px 80px;
}

/*Left*/
.more-filter-modal .modal.left.fade .modal-dialog{
    left: -320px;
    -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
         -o-transition: opacity 0.3s linear, left 0.3s ease-out;
            transition: opacity 0.3s linear, left 0.3s ease-out;
}

.more-filter-modal .modal.left.fade.in .modal-dialog{
    left: 0;
}
    
/*Right*/
.more-filter-modal .modal.right.fade .modal-dialog {
    right: -320px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
       -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
         -o-transition: opacity 0.3s linear, right 0.3s ease-out;
            transition: opacity 0.3s linear, right 0.3s ease-out;
}

.more-filter-modal .modal.right.fade.in .modal-dialog {
    right: 0;
}


/* ----- MODAL STYLE ----- */
.more-filter-modal .modal-content {
    border-radius: 0;
    border: none;
}

.more-filter-modal .modal-header {
    position: relative;
}

.more-filter-modal .close-icon {
  position: absolute;
  right: 10px;
  height: 80px;
}