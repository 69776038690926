.modal-wrapper-adgroup .modal-dialog{
    width: 100%;
    max-width: 1200px;
    height: 500px;
}

.modal-wrapper-adgroup-scroll {
    overflow-y: scroll;
}


.modal-wrapper-adgroup .modal-content {
    width: 100%;
}