.search-term-table-wrapper .search-term-main-table .search-term-table th:nth-child(1) {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 45;
    opacity: 100%;
    min-width: 250px;
    /* box-shadow: 2px 1px 6px #c9705a91; */
    border-right: 1px solid #e47518;
}

.search-term-table-wrapper .search-term-main-table .search-term-table th:nth-child(2) {
    min-width: 150px;
    /* position: sticky;
    left: 250px;
    top: 0;
    z-index: 45;
    opacity: 100%;
    box-shadow: 2px 1px 6px #c9705a91;
    border-right: 1px solid #e47518; */
}
.search-term-table-wrapper .search-term-main-table .search-term-table th:nth-child(3) {
    min-width: 150px;
}

.search-term-table-wrapper .search-term-main-table .search-term-table .targeting-table-heads {
    padding: 8px 20px;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 30;
    opacity: 100%;
    border-right: 1px solid white;
    text-align: left;
}

.search-term-table-wrapper .search-term-main-table .search-term-table .ad-campaign-details td {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    background-color: #F2F7FE;
    text-align: left;
}

.search-term-table-wrapper .search-term-main-table .search-term-table tr .empty-td-fixed {
    min-width: 130px !important;
}

.search-term-table-wrapper .search-term-main-table .search-term-table {
    border-collapse: initial;
}

.search-term-table-wrapper .search-term-main-table .search-term-table .ads-td-second{
    position: sticky;
    left: 0px;
    z-index: 21;
    width: fit-content;
    background-color: #fff;
    /* height: 52px; */
    /* background: #F3F1FC; */
    /* padding: 12px 15px; */
    height: auto;
    border-bottom: 0.5px solid #cccccc;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;
}

.alignment-top {
    top: 298px;
}

.search-bar-wrapper-ms {
    margin-right: 15px;
}



.mst-tab-dynamic-width {
    width: 150px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.search-term-table-wrapper .search-term-main-table .search-term-table .ads-td-first{
    position: sticky;
    left: 0px;
    z-index: 21;
    width: 368px;
    background-color: #fff;
    /* height: 52px; */
    /* background: #F3F1FC; */
    /* padding: 12px 15px; */
    height: auto;
    border-bottom: 0.5px solid #cccccc;
    border-right: 1px solid #CCCC;
    box-shadow: 2px 1px 6px #cccc;
}

.search-term-table-wrapper .search-term-main-table .search-term-table tr td {
    border-right: 0.5px solid #cccccc;
    border-bottom: 0.5px solid #cccccc;
}

.mst-filterIcon {
    height: 36px;
    width: 24px;
}

.breadcrumb-container-master-search-term {
    margin-top: 3.36px;
}